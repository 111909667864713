<template>
  <div class="approval-detail-view">
    <section class="detail-comment">
      <div class="inner-box">
        <div class="comment-left">
          <user-card
            v-if="type !== 'channel'"
            :obj="{
              avatar: initiateObj.avatar,
              name: initiateObj.promoterName,
              job: initiateObj.promoterOrgChain,
              userId: initiateObj.promoterId
            }"
          >
            <template #reward>
              <span class="reward" v-if="initiateObj.processState === 6"
                >奖励{{ initiateObj.finalScore || 0 }}分</span
              >
            </template>
            <template #status>
              <div class="status-box">
                <span class="num">NO.{{ initiateObj.innovateCode || '暂无' }}</span>
                <div>
                  <span class="status" :class="`status-${initiateObj.processState}`"
                    >{{
                      initiateObj.processStateName
                        | formateProcessState(initiateObj.approverName, initiateObj.processState)
                    }}
                  </span>
                  <span class="instructions" @click="showDialog = true">审批说明</span>
                </div>
              </div>
            </template>
          </user-card>
          <div class="list-code" v-else>
            <span class="num">NO.{{ initiateObj.innovateCode || '暂无' }}</span>
          </div>
          <div class="item-box">
            <div
              class="application"
              v-for="(item, indexC) in formSelList"
              :key="indexC"
              v-show="item.code"
            >
              <div class="title">{{ item.title }}</div>
              <div class="value">{{ item.value }}</div>
            </div>
          </div>
          <div class="form-box">
            <div class="item" v-for="(item, index) in formList" :key="index" v-show="item.code">
              <div class="title">{{ item.title }}</div>
              <p class="value">{{ item.value }}</p>
            </div>
          </div>
          <div class="file-box" v-if="fileObj.code && fileList.length > 0">
            <div class="file-title">{{ fileObj.title || '文件上传' }}</div>
            <tojoy-file
              class="file-item"
              v-for="(file, index) in fileList"
              :key="index"
              :data="{
                type: file.fileSuffix,
                fileName: file.fileName || '暂无',
                url: file.filePath
              }"
            />
          </div>
        </div>
        <div class="comment-right" v-if="type !== 'channel'">
          <div class="title">
            流程记录
          </div>
          <record-list :list="activities" :score="score" />
        </div>
      </div>
    </section>
    <div
      class="result-bottom"
      v-if="type === 'initiate' || (type === 'approval' && initiateObj.isOpen)"
    >
      <!-- 审批中4  已撤回2 已驳回5  审批通过6 -->
      <el-button
        :class="item.className"
        :type="item.type"
        @click="item.fn(item.code)"
        v-show="item.show"
        v-for="(item, index) in btnConfig"
        :key="index"
        >{{ item.text }}</el-button
      >
    </div>
    <!-- 审批说明弹框 -->
    <approvalExplanation :showDialog="showDialog" />
    <!-- 通过 -->
    <pass :showDialog="showPassDialog" :passObj="passObj" :moduleName="moduleName" />
    <!-- 委托他人 -->
    <entrust :showDialog="showEntrustDialog" :entrustObj="entrustObj" :moduleName="moduleName" />
    <!-- 驳回 -->
    <rejected :showDialog="showRejectedDialog" :rejectObj="rejectObj" :moduleName="moduleName" />
  </div>
</template>

<script>
import {
  getInnovationDetail,
  getInnovationDetailProcess,
  postProcessRevoke,
  postInnovationDelete
} from '@/api/api_innovation'
import { MessageBox, Message } from 'element-ui'
import detailMixin from '@/mixins/initiate/detailMixin.js'
export default {
  name: 'approval-detail',
  mixins: [detailMixin],
  data() {
    return {
      formSelList: [
        {
          title: '创新应用范围',
          cname: 'innovateLevelName',
          value: '',
          code: null
        },
        {
          title: '实施单位',
          cname: 'implementOrgName',
          value: '',
          code: null
        },
        {
          title: '提案类别',
          cname: 'innovateCategoryName',
          value: '',
          code: null
        },
        {
          title: '提案日期',
          cname: 'proposalDate',
          value: '',
          code: null
        }
      ],
      formList: [
        {
          title: '标题',
          cname: 'title',
          value: '',
          code: null
        },
        {
          title: '要点',
          cname: 'gist',
          value: '',
          code: null
        },
        {
          title: '目前现状',
          cname: 'currentSituation',
          value: '',
          code: null
        },
        {
          title: '创新具体成果',
          cname: 'innovateContent',
          value: '',
          code: null
        },
        {
          title: '实施步骤',
          cname: 'implementContent',
          value: '',
          code: null
        },
        {
          title: '预测取得效果',
          cname: 'forecastResult',
          value: '',
          code: null
        }
      ]
    }
  },
  async created() {
    await this.handleDetail()
    this.initBtn()
    this.handleprocessList()
  },
  methods: {
    /**
     * 详情
     */
    async handleDetail() {
      let params = {
        innovateCode: this.id
      }
      await getInnovationDetail(params)
        .then(res => {
          if (res.data) {
            this.initiateObj = res.data
            let {
              innovateLevelName,
              implementOrgName,
              innovateCategoryName,
              proposalDate,
              title,
              gist,
              currentSituation,
              implementContent,
              forecastResult,
              innovateAnnexList,
              innovateContent,
              innovateAnnexListFormCode,
              innovateAnnexListFormName
            } = res.data
            let obj = {
              title,
              gist,
              currentSituation,
              implementContent,
              forecastResult,
              innovateContent
            }

            let selObj = {
              innovateLevelName,
              implementOrgName,
              innovateCategoryName,
              proposalDate
            }
            this.handlesetDetail(obj, selObj)
            this.$set(this.fileObj, 'code', innovateAnnexListFormCode)
            this.$set(this.fileObj, 'title', innovateAnnexListFormName)
            this.fileList = innovateAnnexList
          } else {
            this.initiateObj = {}
            if (res.code === '000015' || res.code === '000500') {
              setTimeout(() => {
                this.$router.push({
                  name: 'channel'
                })
              }, 700)
            }
          }
        })
        .catch(err => {
          console.log(err)
        })
    },
    /**
     * 流程记录
     */
    handleprocessList() {
      getInnovationDetailProcess({ innovateCode: this.id })
        .then(res => {
          let { innovateProcessOperateLogOutputList, finalScoreStr, finalScore } = res.data
          this.activities = innovateProcessOperateLogOutputList
          this.handleSetProcesData(finalScoreStr, finalScore)
        })
        .catch(err => {})
    },
    handleDialog(code) {
      //审批状态 5 驳回  reject  4通过pass
      this.handleSetDialog(code, this.initiateObj.innovateLevelName, this.initiateObj.innovateLevel)
    },
    /**
     * 撤回
     */
    handleRevoke() {
      this.handleRevokeFn({ innovateCode: this.id }, postProcessRevoke, 'initiate')
    },
    /**
     * 删除
     */
    handleDelete() {
      this.handleDeleteFn({ innovateCode: this.id }, postInnovationDelete, 'initiate')
    },
    /**
     * 撤回 删除确认框
     * type  invoke 撤回  delete 删除
     */
    handleMessageBox(type) {
      let text = type === 'delete' ? '删除' : '撤回'
      MessageBox.confirm(`确认${text}此创新提案`, '提示', {
        confirmButtonText: `确定${text}`,
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          if (type === 'revoke') {
            this.handleRevoke()
          } else {
            this.handleDelete()
          }
        })
        .catch(() => {
          Message.info(`已取消操作`)
        })
    },
    initBtn() {
      this.initBtnFn(this.handleMessageBox, this.handleEdit, this.handleDialog)
    },
    handleEdit() {
      this.handleEditFn('initiateProposal')
    }
  }
}
</script>
<style lang="scss">
@import '@/assets/css/initiate.scss';
</style>
